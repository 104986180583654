
export const carsTop =[
    {name:'Audi'},
    {name:'BMW'},
    {name:'Ford'},
    {name:'Honda'},
    {name:'Hyundai'},
    {name:'Mazda'},
    {name:'Mercedes-Benz'},
    {name:'Mitsubishi'},
    {name:'Nissan'},
    {name:'Opel'},
    {name:'Toyota'},
    {name:'Volkswagen'}
]

export const cars =   [
    {name:'Acura'},
    {name:'Audi'},
    {name:'Bentley'},
    {name:'BMW'},
    {name:'Cadillac'},
    {name:'Chevrolet'},
    {name:'Chrysler'},
    {name:'Citroen'},
    {name:'Chery'},
    {name:'Daewoo'},
    {name:'Dodge'},
    {name:'Fiat'},
    {name:'Ford'},
    {name:'Honda'},
    {name:'Hyundai'},
    {name:'Infiniti'},
    {name:'Isuzu'},
    {name:'Jaguar'},
    {name:'Jeep'},
    {name:'Kia'},
    {name:'Land Rover'},
    {name:'Lexus'},
    {name:'Mazda'},
    {name:'Mercedes-Benz'},
    {name:'Mini'},
    {name:'Mitsubishi'},
    {name:'Nissan'},
    {name:'Opel'},
    {name:'Peugeot'},
    {name:'Porsche'},
    {name:'Renault'},
    {name:'Samand'},
    {name:'Skoda'},
    {name:'Subaru'},
    {name:'Suzuki'},
    {name:'Toyota'},
    {name:'Volkswagen'},
    {name:'Volvo'},
    {name:'ВАЗ LADA'},
    {name:'ГАЗ Волга'},
    {name:'УАЗ'}
]


export const autogruz = [
    {name:'DAF'},
    {name:'HOWO'},
    {name:'Iveco'},
    {name:'MAN'},
    {name:'Mercedes-Benz'},
    {name:'Renault'},
    {name:'Scania'},
    {name:'Volvo'},
    {name:'ГАЗ'},
    {name:'ЗИЛ'},
    {name:'КамАЗ'},
    {name:'КрАЗ'},
    {name:'МАЗ'},
    {name:'УАЗ'}
]

export const parts = [
    {id:1,name:'Ամրակներ'},
    {id:2,name:'Այրման մոմիկներ (Свеча)'},
    {id:3,name:'Անվադող և անվասկավառակ'},
    {id:4,name:'Ավտոաքսեսուարներ'},
    {id:5,name:'Ավտոգործիքներ և նորոգման նյութեր'},
    {id:6,name:'Ավտոտեխսպասարկման սարքավորումներ'},
    {id:7,name:'Ավտոլամպեր'},
    {id:8,name:'Ավտոմեքենայի կողային հայելիներ'},
    {id:9,name:'Ավտոքիմիա և ավտոկոսմետիկա'},
    {id:10,name:'Արգելակման կոճղակներ'},
    {id:11,name:'Զտիչներ (филтри)'},
    {id:12,name:'Մարտկոցներ'},
    {id:13,name:'Յուղեր և քսանյութեր'},
    {id:14,name:'Ավտոապակիներ'},
    {id:15,name:'Գազի պատագաներ'},
    {id:16,name:'Փոկեր (ремень)'},
    {id:17,name:' Անվտանգության բարձիկներ'},
]



export const service = [
    {name:'Ավտոդպրոց'},
    {name:'ԱՊՊԱ'},
    {name:'Անվաբացքի ուղղում (Развал-схождение)'},
    {name:'Անվադողի, անվասկավառակի նորոգում'},
    {name:'Անվտանգության բարձիկների  և ամրագոտիների վերանորոգում'},
    {name:'Ավտոապակիների մգեցում'},
    {name:'Կողային հայելիների վերանորոգում'},
    {name:'Ավտոներկերի գունաբռնում'},
    {name:'Բեռնատարների նորոգում (Фура)'},
    {name:'Բեռնափոխադրում'},
    {name:'Գազի տեղադրում և սպասարկում'},
    {name:'Գազաբալոնի պաստառապատում'},
    {name:'Գազի լաբորատորիա'},
    {name:'Գնդաձև դետալների նորոգում (тяги, шаровые, стойки)'},
    {name:'Դիագնոստիկա և ավտոէլեկտրիկ'},
    {name:'Դիագնոստիկա SRS-Airbag'},
    {name:'Դռների բացում (24 ժ)'},
    {name:'Բանալիների պատրաստում'},
    {name:'Ընթացամասի նորոգում (Ходовик)'},
    {name:'Թափքի զոդում, թիթեղագործ և ներկարար'},
    {name:'Ինժեկտորնորի մաքրում'},
    {name:'Ինքնակպչուն և մագնիս'},
    {name:'Լուսարձակների կարգավորում'},
    {name:'Լուսարձակների փայլեցում'},
    {name:'Խառատային և ֆրեզային աշխատանքներ'},
    {name:'Խլացուցիչի նորոգում'},
    {name:'Կոնդիցիոներների նորոգում'},
    {name:'Մուշտուկների նորոգում'},
    {name:'Յուղման կետ'},
    {name:'Շարժիչի նորոգում'},
    {name:'Ուղևորափոխադրում'},
    {name:'Ռադիատորների նորոգում'},
    {name:'Սրահի վերանորոգում'},
    {name:'Ստենդ և չորանոց'},
    {name:'Սրահի քիմ մաքրում և ավտոլվացում'},
    {name:'Փոխանցման տուփի նորոգում'},
    {name:'Քարշակ ծառայություն'},
    {name:'Շարժական դիագնոստիկա'},
    {name:'Շարժական վուլկանացում'},
    {name:'«Սթափ վարորդ» ծառայություն'},
    {name:'Մարտկոցների լիցքավորում'},
    {name:'Վառելիքի լիցքավորում'},
    {name:'Ղեկի տեղափոխում'}
]




// {"Ամրագոտիների վերանորոգում"},
// {"ԱՊՊԱ"},
// {"Անվաբացքի ուղղում (Развал-схождение)"},
// {"Անվադողի, անվասկավառակի նորոգու"},
// {"Անվտանգության բարձիկների վերանորոգում"},
// {"Ավտոապակիների մգեցում"},
// {"Ավտոապակիների մգեցում"},
// {"Ավտոմեքենայի կողային հայելիների վերանորոգում"},
// {"Ավտոներկերի գունաբռնում"},
// {"Բեռնատարների նորոգում (Фура)"},
// {"Բեռնափոխադրում"},
// {"Գազի տեղադրում և սպասարկում"},
// {"Գազաբալոնի պաստառապատում 	},
// {"Գազի լաբորատորիա"},
// {"Գնդաձև դետալների նորոգում (тяги, шаровые, стойки)"},
// {"Դիագնոստիկա և ավտոէլեկտրիկ"},
// {"Դիագնոստիկա SRS-Airbag"},
// {"Դռների բացում (24 ժ)"},
// {"Ընթացամասի նորոգում (Ходовик)"},
// {"Թափքի զոդում, թիթեղագործ և ներկարար"},
// {"Ինժեկտորնորի մաքրում"},
// {"Ինքնակպչուն և մագնիս"},
// {"Լուսարձակների կարգավորում"},
// {"Լուսարձակների փայլեցում"},
// {"Խառատային և ֆրեզային աշխատանքներ"},
// {"Խլացուցիչի նորոգում"},
// {"Կոնդիցիոներների նորոգում"},
// {"Մուշտուկների նորոգում"},
// {"Յուղման կետ"},
// {"Շարժիչի նորոգում"},
// {"Ուղևորափոխադրում"},
// {"Ռադիատորների նորոգում"},
// {"Սրահի վերանորոգում"},
// {"Ստենդ և չորանոց"},
// {"Սրահի քիմ մաքրում և ավտոլվացում"},
// {"Փոխանցման տուփի նորոգում"},
// {"Քարշակ ծառայություն"},
