const SITE_NAME = 'https://v8.am';

export const Url = {
    login: SITE_NAME + '/Server/login',
    existUser: SITE_NAME + '/Server/user-exist',
    logout: SITE_NAME + '/Server/logout',
    location: SITE_NAME + '/Server/location',
    city: SITE_NAME + '/Server/location/city',
    registration: SITE_NAME + '/Server/Reg',
    auto: SITE_NAME + '/Server/auto',
    allmodels: SITE_NAME + '/Server/model',
    addMarkModelAutoParts: SITE_NAME + '/Server/ammap',
    getMarkModelAutoParts: SITE_NAME + '/Server/gmmap',
    model: SITE_NAME + '/Server/auto/model',
    addauto: SITE_NAME + '/Server/AddUserAuto',
    getUserAuto: SITE_NAME + '/Server/GetUserAuto',
    sell: SITE_NAME + '/Server/sell',
    refuse: SITE_NAME + '/Server/RefuseSell',
    getsell: SITE_NAME + '/Server/GetSell',
    getsellautobyid: SITE_NAME + '/Server/get_sell__auto_by_id',
    DeleteUserAuto: SITE_NAME + '/Server/DeleteUserAuto',
    UserUpdate: SITE_NAME + '/Server/ubdate_user_data',
    addscore: SITE_NAME + '/Server/AddScoreAccount',
    addscorelist: SITE_NAME + '/Server/add-score-list',
    addproduct: SITE_NAME + '/Server/add-product',
    getproduct: SITE_NAME + '/Server/get-product',
    setingsproduct: SITE_NAME + '/Server/setings-product',
    getscorelist: SITE_NAME + '/Server/get-score-list',
    getstoreforcustomer: SITE_NAME + '/Server/customer-detals',
    loginscore: SITE_NAME + '/Server/score-log',
    logoutscore: SITE_NAME + '/Server/score_l',
    existScore: SITE_NAME + '/Server/score-exist',
    scoreSetings: SITE_NAME + '/Server/score-setings',
    scoreSetingsUser:SITE_NAME + '/Server/score_user_setings',
    getFavorite: SITE_NAME + '/Server/favorite-get',
    addFavorite: SITE_NAME + '/Server/favorite-add',
    sendFavorite: SITE_NAME + '/Server/favorite-send',
    getMessage: SITE_NAME + '/Server/message-get',
    sendMessage: SITE_NAME + '/Server/message-send',
    dialog: SITE_NAME + '/Server/dialog',
    messageId: SITE_NAME + '/Server/message-id',
    messageStatus: SITE_NAME + '/Server/message-status',
    deliteMessage: SITE_NAME + '/Server/message_delite-',
    tmpimg: SITE_NAME + '/Server/tmp-img',
    searchscore: SITE_NAME + '/Server/isset_detal',
    truck: SITE_NAME + '/Server/track',
    truckID: SITE_NAME + '/Server/track_by_id',
    truckModels: SITE_NAME + '/Server/track_m',
    searchmarkmodel: SITE_NAME + '/Server/mmn_search',
    watckcount: SITE_NAME + '/Server/watch-count',
    getallauto: SITE_NAME + '/Server/auto-get',
    getallproduct: SITE_NAME + '/Server/product-get',
    addrequest: SITE_NAME + '/Server/add-request',
    getrequest: SITE_NAME + '/Server/get-request',
    sendrequest: SITE_NAME + '/Server/send-request',
    delrequest: SITE_NAME + '/Server/del-request',
    statusrequest: SITE_NAME + '/Server/status-request',
    ubdatestatus: SITE_NAME + '/Server/update-status',
    updateAutoImg:SITE_NAME + '/Server/auto-update-img',
    updateAutoInfo:SITE_NAME + '/Server/auto_update_info',
    addAutoImg:SITE_NAME + '/Server/auto-add-img',
    mmpd:SITE_NAME + '/Server/mmpd',
    mail:SITE_NAME + '/Server/mail',
    getService:SITE_NAME + '/Server/services',
    addServices: SITE_NAME + '/Server/services-add',
    searchServices: SITE_NAME + '/Server/services-search',
    getMyService:SITE_NAME + '/Server/services-get',
    getserviceList: SITE_NAME + '/Server/service-list',
    partsAndAccessories: SITE_NAME + '/Server/service-get-search-links',
    partsMyAndAccessories: SITE_NAME + '/Server/service-my-get-search-links',
    partsAddAndAccessories: SITE_NAME + '/Server/service-my-add-search-links',
    partsSearchAccessories: SITE_NAME + '/Server/service-my-search-links-product',

    // Admin
    admin: SITE_NAME + '/Server/admin',
    issetAdmin: SITE_NAME + '/Server/isset-admin',
    logoutAdmin: SITE_NAME + '/Server/logout-admin',
    addSliderImgAdmin: SITE_NAME + '/Server/add-slider-img-admin',
    getSliderImgAdmin: SITE_NAME + '/Server/get-slider-img-admin',
    delSliderImgAdmin: SITE_NAME + '/Server/del-slider-img-admin',

    // Restore password

    restore: SITE_NAME + '/Server/password-res',
    changePassword: SITE_NAME + '/Server/change-password',

}
export default SITE_NAME;

