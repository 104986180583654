import React, {Component} from "react";

class ListAutoService extends Component {
    constructor() {
        super();
    }

    render() {
        return(
            <div className='formAutoService'>
                <h1>bbbb</h1>
            </div>
        )
    }
}

export default ListAutoService;